import { useEffect, useState } from 'react';
import colorPalette from '../styles/colors';
import { Link } from 'react-router-dom';

import logo from '../assets/img/isologo.png';

export function Footer() {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   // STYLES

   const isMobile = windowWidth < 700;

   const styles = {
      footerContainer: {
         display: 'flex',
         flexWrap: isMobile ? 'wrap' : 'nowrap',
         justifyContent: 'center',
         alignItems: 'flex-end',
         backgroundColor: colorPalette.primary[500],
         height: '400px',
         padding: isMobile ? '5.5vw' : '24px',
      },

      footerSubContainerA: {
         display: 'flex',
         flexDirection: 'column',
         /* backgroundColor: 'blue', */
         width: isMobile ? '95vw' : '250px',
         height: isMobile ? '350px' : '400px',
         paddingLeft: isMobile ? '0px' : '24px',
      },

      footerSubContainerB: {
         display: 'flex',
         flexDirection: 'column',
         position: isMobile ? 'absolute' : '',
         left: isMobile ? '5.5vw' : '',
         /* backgroundColor: 'red', */
         width: isMobile ? '130px' : '250px',
         height: isMobile ? '4vh' : '400px',
      },

      footerText: {
         color: colorPalette.lightGrey[200],
         fontFamily: '"Roboto", sans-serif',
         width: isMobile ? '180px' : '280px',
         height: isMobile ? '17px' : '25px',
         fontSize: isMobile ? '0.8em' : '18px',
         fontWeight: '300',
         /* backgroundColor: 'green', */
         margin: '0',
         marginBottom: '10px',
         letterSpacing: '1px',
      },

      footerTextA: {
         color: colorPalette.lightGrey[200],
         fontFamily: '"Roboto", sans-serif',
         width: isMobile ? '190px' : '280px',
         height: isMobile ? '17px' : '25px',
         fontSize: isMobile ? '0.95em' : '18px',
         fontWeight: '300',
         /* backgroundColor: 'green', */
         margin: '0',
         marginTop: '18px',
         letterSpacing: '1px',
      },

      footerTextCr: {
         color: colorPalette.lightGrey[200],
         fontFamily: '"Roboto", sans-serif',
         width: isMobile ? '170px' : '250px',
         height: '25px',
         fontSize: isMobile ? '10px' : '12px',
         fontWeight: '300',
         /* backgroundColor: 'green', */
         margin: '0',
         marginBottom: '5px',
         letterSpacing: isMobile ? '0px' : '1px',
      },

      footerImg: {
         position: 'relative',

         right: isMobile ? '-17px' : '1px',
         height: isMobile ? '11vw' : '50px',
         marginRight: isMobile ? '5.5vw' : '',
      },

      separator: {
         /* backgroundColor: 'yellow', */
         flexGrow: 1,
      },
      separator1: {
         /* backgroundColor: 'yellow', */
         flexGrow: 1,
      },
   };

   return (
      <div style={styles.footerContainer}>
         <div style={styles.footerSubContainerA}>
            <Link to="/contact" style={{ textDecoration: 'none' }}>
               <h2 style={styles.footerTextA}>
                  <b>CONTACTANOS</b>
               </h2>
            </Link>
            <Link to="/about" style={{ textDecoration: 'none' }}>
               <h2 style={styles.footerTextA}>
                  <b>ACERCA DE NOSOTROS</b>
               </h2>
            </Link>
            <Link to="/services" style={{ textDecoration: 'none' }}>
               <h2 style={styles.footerTextA}>
                  <b>NUESTROS SERVICIOS</b>
               </h2>
            </Link>

            <div style={styles.separator1}></div>

            <h2 style={styles.footerText}>
               <b>CHILE</b>
            </h2>
            <h2 style={styles.footerText}>(+56)9 4900-9461</h2>
            <h2 style={styles.footerText}>(+56)9 4900-9472</h2>

            <h2 style={styles.footerText}>
               <b>ARGENTINA</b>
            </h2>
            <h2 style={styles.footerText}>(+54)9 3547-577237</h2>
         </div>

         <div style={styles.separator}></div>

         <div style={styles.footerSubContainerB}>
            {/* <p style={styles.footerText}>Todos los derechos reservados.</p> */}
            <div style={styles.separator1}></div>
            <p style={styles.footerTextCr}>
               <i>Todos los derechos reservados © 2023</i>
            </p>
         </div>

         <img style={styles.footerImg} src={logo} alt="Words" />
      </div>
   );
}
