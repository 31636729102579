const colorPalette = {
   primary: {
      50: '#e0e0e0',
      100: '#b3b3b3',
      200: '#808080',
      300: '#4d4d4d',
      400: '#262626',
      500: '#000000',
      600: '#000000',
      700: '#000000',
      800: '#000000',
      900: '#000000',
      A100: '#a6a6a6',
      A200: '#8c8c8c',
      A400: '#737373',
      A700: '#666666',
      contrastDefaultColor: 'light',
   },

   yellow: {
      50: '#fef8ec',
      100: '#fceed0',
      200: '#fae2b1',
      300: '#f7d691',
      400: '#f6ce7a',
      500: '#f4c562',
      600: '#f3bf5a',
      700: '#f1b850',
      800: '#efb046',
      900: '#eca334',
      A100: '#ffffff',
      A200: '#ffffff',
      A400: '#ffeacc',
      A700: '#ffdfb2',
      contrastDefaultColor: 'dark',
   },

   red: {
      50: '#f9e9ea',
      100: '#efc7c9',
      200: '#e4a2a6',
      300: '#d97c82',
      400: '#d16067',
      500: '#c9444c',
      600: '#c33e45',
      700: '#bc353c',
      800: '#b52d33',
      900: '#a91f24',
      A100: '#ffe2e3',
      A200: '#ffafb1',
      A400: '#ff7c80',
      A700: '#ff6367',
      contrastDefaultColor: 'light',
   },

   blue: {
      50: '#ebf0f7',
      100: '#cddbec',
      200: '#abc3df',
      300: '#89aad2',
      400: '#7098c8',
      500: '#5786be',
      600: '#4f7eb8',
      700: '#4673af',
      800: '#3c69a7',
      900: '#2c5699',
      A100: '#dde9ff',
      A200: '#aac9ff',
      A400: '#77a9ff',
      A700: '#5d99ff',
      contrastDefaultColor: 'light',
   },

   green: {
      50: '#eef5f0',
      100: '#d5e5da',
      200: '#b9d4c2',
      300: '#9dc3a9',
      400: '#88b696',
      500: '#73a984',
      600: '#6ba27c',
      700: '#609871',
      800: '#568f67',
      900: '#437e54',
      A100: '#d7ffe2',
      A200: '#a4ffbd',
      A400: '#71ff99',
      A700: '#58ff86',
      contrastDefaultColor: 'dark',
   },

   orange: {
      50: '#fdf1ed',
      100: '#fadbd1',
      200: '#f7c4b3',
      300: '#f3ac94',
      400: '#f19a7d',
      500: '#ee8866',
      600: '#ec805e',
      700: '#e97553',
      800: '#e76b49',
      900: '#e25838',
      A100: '#ffffff',
      A200: '#fff9f8',
      A400: '#ffcfc5',
      A700: '#ffbaac',
      contrastDefaultColor: 'dark',
   },

   beige: {
      50: '#fbf9f5',
      100: '#f6f0e7',
      200: '#f0e7d7',
      300: '#eaddc6',
      400: '#e6d5ba',
      500: '#e1ceae',
      600: '#ddc9a7',
      700: '#d9c29d',
      800: '#d5bc94',
      900: '#cdb084',
      A100: '#ffffff',
      A200: '#ffffff',
      A400: '#fffaf3',
      A700: '#fff0da',
      contrastDefaultColor: 'dark',
   },

   burdeo: {
      50: '#efe6e8',
      100: '#d7c0c5',
      200: '#bd979f',
      300: '#a36d78',
      400: '#8f4d5b',
      500: '#7b2e3e',
      600: '#732938',
      700: '#682330',
      800: '#5e1d28',
      900: '#4b121b',
      A100: '#ff8596',
      A200: '#ff526a',
      A400: '#ff1f3e',
      A700: '#ff0628',
      contrastDefaultColor: 'light',
   },

   grey: {
      50: '#eaebed',
      100: '#caced1',
      200: '#a7adb3',
      300: '#848c94',
      400: '#69747d',
      500: '#4f5b66',
      600: '#48535e',
      700: '#3f4953',
      800: '#364049',
      900: '#262f38',
      A100: '#83c1ff',
      A200: '#50a7ff',
      A400: '#1d8eff',
      A700: '#0381ff',
      contrastDefaultColor: 'light',
   },

   lightGrey: {
      50: '#f8f9f9',
      100: '#edf0f1',
      200: '#e2e6e8',
      300: '#d6dbde',
      400: '#cdd4d7',
      500: '#c4ccd0',
      600: '#bec7cb',
      700: '#b6c0c4',
      800: '#afb9be',
      900: '#a2adb3',
      A100: '#ffffff',
      A200: '#ffffff',
      A400: '#f6fcff',
      A700: '#dcf4ff',
      contrastDefaultColor: 'dark',
   },
};

export default colorPalette;
