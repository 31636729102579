import { useEffect, useState } from 'react';
import colorPalette from '../styles/colors';

export function Contact() {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   useEffect(() => {
      window.scrollTo(0, 0); // Desplaza la página al inicio al cargar la nueva ruta
   }, []);

   // STYLES

   const isMobile = windowWidth < 700;

   const styles = {
      contactContainer: {
         position: 'relative',
         top: '-70px',
         paddingTop: isMobile ? '37vh' : '250px',
         paddingLeft: '10vw',
         paddingRight: '10vw',
         height: isMobile ? '50vh' : '600px',
         backgroundColor: colorPalette.lightGrey[200],
         textAlign: 'center',
      },
   };

   return (
      <div style={styles.contactContainer} id="contact">
         <p>Diseñamos sistemas de reciclaje y gestión de residuos para proyectos habitacionales y comerciales. </p>
         <p>Tienes un proyecto? Escríbenos </p>
         <p>
            <b>hello@circolar.com</b>
         </p>
      </div>
   );
}
