import '../components/Banner.css';
import colorPalette from '../styles/colors';
import logoIkea from '../assets/img/clients/ikea.png';
import logoTriciclos from '../assets/img/clients/triciclos.png';
import logoImagina from '../assets/img/clients/imagina.png';
import logoMaestra from '../assets/img/clients/maestra.png';
import logoAustralis from '../assets/img/clients/australis.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import '../components/Client.css';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

export function Client() {
   const styles = {
      clientContainer: {
         position: 'relative',
         top: '-65px',
         display: 'flex',

         justifyContent: 'center',
         alignItems: 'center',

         maxWidth: '100vw',
         height: '150px',
         paddingLeft: '5vw',
         paddingRight: '5vw',

         backgroundColor: 'rgba(226, 230, 232, 0.85)', // despues cambiar por color en paleta
      },

      titleContainer: {
         position: 'absolute',
         top: '0px',
         backgroundColor: '#4D4D4D',
         width: '100%',
         height: '20px',
      },

      title: {
         position: 'absolute',
         top: '-12.1px',
         left: '12vw',
         fontFamily: '"Roboto", sans-serif',
         fontStyle: 'italic',
         fontSize: '15px',
         fontWeight: '300',
         letterSpacing: '1px',
         color: colorPalette.lightGrey[200],
      },

      logos: {
         marginLeft: '35px',
         marginRight: '35px',
      },
   };

   return (
      <div className="client-container" style={styles.clientContainer}>
         <div style={styles.titleContainer}>
            <h2 style={styles.title}>Proyectos</h2>
         </div>
         <Swiper
            slidesPerView={5}
            spaceBetween={30}
            autoplay={{
               delay: 2500,
               disableOnInteraction: false,
            }}
            pagination={{
               el: '.swiper-pagination',
               clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
         >
            <SwiperSlide>
               <img src={logoIkea} style={styles.logos} height="55px" alt="ikea" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoAustralis} style={styles.logos} height="75px" alt="australis" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoMaestra} style={styles.logos} height="35px" alt="maestra" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoImagina} style={styles.logos} height="45px" alt="imaginainmobiliaria" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoTriciclos} style={styles.logos} height="25px" alt="triciclos" />
            </SwiperSlide>
         </Swiper>
         <Swiper
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
               delay: 2500,
               disableOnInteraction: false,
            }}
            pagination={{
               el: '.swiper-pagination',
               clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiperMobile"
         >
            <SwiperSlide>
               <img src={logoIkea} style={styles.logos} height="55px" alt="ikea" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoAustralis} style={styles.logos} height="75px" alt="australis" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoMaestra} style={styles.logos} height="35px" alt="maestra" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoImagina} style={styles.logos} height="45px" alt="imaginainmobiliaria" />
            </SwiperSlide>
            <SwiperSlide>
               <img src={logoTriciclos} style={styles.logos} height="25px" alt="triciclos" />
            </SwiperSlide>
         </Swiper>
      </div>
   );
}
