import './App.css';
import { HideAppBar, Banner, Client, Card, About, Services, Contact, Footer } from './components';
import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
   return (
      <div className="app-main-container">
         <Routes>
            <Route
               path="/"
               exact
               element={
                  <div>
                     <HideAppBar />
                     <Banner />
                     <Client />
                     <Card />
                     <Footer />
                  </div>
               }
            />

            <Route
               path="/about"
               exact
               element={
                  <div>
                     <HideAppBar />
                     <About />
                     <Footer />
                  </div>
               }
            />

            <Route
               path="/services"
               exact
               element={
                  <div>
                     <HideAppBar />
                     <Services />
                     <Footer />
                  </div>
               }
            />

            <Route
               path="/contact"
               exact
               element={
                  <div>
                     <HideAppBar />
                     <Contact />
                     <Footer />
                  </div>
               }
            />

            {/* <Route path="*" element={<div>404 - no hay paginaaaa weeee</div>} /> */}
            <Route path="*" element={<Navigate replace to="/" />} />
         </Routes>
      </div>
   );
}

export default App;
