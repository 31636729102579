import { useEffect, useState } from 'react';
import '../components/Card.css';
import colorPalette from '../styles/colors';
import imageA from '../assets/img/cards/imageAbw.png';
import imageB from '../assets/img/cards/imageBbw.png';
import imageC from '../assets/img/cards/imageCbw.png';
import imageD from '../assets/img/cards/imageDbw.png';
import imageE from '../assets/img/cards/imageEbw.png';
import imageF from '../assets/img/cards/imageFbw.png';
import imageG from '../assets/img/cards/imageGbw.png';
/* import imageH from '../assets/img/cards/imageHbw.png'; */
import imageI from '../assets/img/cards/imageIbw.png';
import imageJ from '../assets/img/cards/imageJbw.png';
/* import imageK from '../assets/img/cards/imageKbw.png'; */

export function Card() {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   // STYLES

   const isMobile = windowWidth < 700;

   const styles = {
      cardImg: {
         width: '100%',
         height: '100%',
         objectFit: 'cover',
      },

      cardDiv: {
         position: 'absolute',
         width: '100%',
         height: '100%',
         zIndex: '1',
      },

      cardText: {
         position: 'absolute',
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         width: '80%',
         height: '400px',
         zIndex: '2',
      },

      cardTextH1: {
         fontFamily: '"Roboto", sans-serif',
         fontSize: '25px',
         fontWeight: '700',
         margin: '0',
         marginBottom: '5px',
         olor: colorPalette.primary[500],
      },

      cardTextH1high: {
         fontFamily: '"Roboto", sans-serif',
         fontSize: '35px',
         fontWeight: '700',
         margin: '0',
         marginBottom: '5px',
         color: colorPalette.primary[500],
      },

      cardTextP: {
         fontFamily: '"Roboto", sans-serif',
         fontSize: '20px',
         fontWeight: '300',
         margin: '5px',
         color: colorPalette.primary[500],
      },

      contactDiv: {
         paddingTop: isMobile ? '210px' : '250px',
         width: '100%',
         height: '400px',
         backgroundColor: colorPalette.lightGrey[200],
         textAlign: 'center',
         paddingLeft: isMobile ? '12vw' : '0px',
         paddingRight: isMobile ? '12vw' : '0px',
      },
   };

   return (
      <div className="cards-container">
         {/* LINE 1 */}

         <div className="card-base cardA">
            <img style={styles.cardImg} src={imageA} alt="cardA"></img>
            <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.blue[300], opacity: '0.70' }}></div>
            <div style={styles.cardText}>
               <h1 id="cards-h1" style={styles.cardTextH1}>
                  Ley de reciclaje LEY REP
               </h1>
               <p id="cards-p" style={styles.cardTextP}>
                  La implementación de la ley de reciclaje implicará la existencia de sistemas de recolección segregada
                  casa a casa en las principales ciudades del país.
               </p>
            </div>
         </div>

         <div className="card-base cardB">
            <img style={styles.cardImg} src={imageB} alt="cardB"></img>
            <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.burdeo[500], opacity: '0.4' }}></div>
            <div style={styles.cardText}>
               <h1 id="cards-h1-high" style={styles.cardTextH1high}>
                  RECICLABLE
               </h1>
            </div>
         </div>

         <div className="card-base cardC">
            <img style={styles.cardImg} src={imageC} alt="cardC"></img>
            <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.green[500], opacity: '0.4' }}></div>
         </div>

         {/* LINEA 2 */}

         <div className="line2start">
            <div className="card-base cardD">
               <img style={styles.cardImg} src={imageD} alt="cardD"></img>
               <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.beige[500], opacity: '0.4' }}></div>
            </div>

            <div className="card-base cardE">
               <img style={styles.cardImg} src={imageE} alt="cardE"></img>
               <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.yellow[500], opacity: '0.5' }}></div>
               <div style={styles.cardText}>
                  <h1 id="cards-h1-high" style={styles.cardTextH1high}>
                     COMPOSTABLE
                  </h1>
               </div>
               {/* <div style={styles.cardText}>
                  <h1 id="cards-h1" style={styles.cardTextH1}>
                     - EMISIONES
                  </h1>
                  <p style={styles.cardTextP}>
                     El reciclaje de vidrio reduce las emisiones de dióxido de carbono en un 20% y las emisiones de
                     óxido de nitrógeno en un 50%.
                  </p>
               </div> */}
            </div>
         </div>

         <div className="card-base cardF">
            <img style={styles.cardImg} src={imageF} alt="cardF"></img>
            <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.burdeo[500], opacity: '0.7' }}></div>
            {/* <div style={styles.cardText}>
               <h1 id="cards-h1" style={{ ...styles.cardTextH1, color: colorPalette.lightGrey[200] }}>
                  METALES
               </h1>
               <p style={{ ...styles.cardTextP, color: colorPalette.lightGrey[200] }}>
                  El reciclaje de una lata de aluminio ahorra aproximadamente un 95% de la energía que se necesitaría
                  para fabricar una lata a partir de materia prima, y se necesitan solo 5% de las emisiones de dióxido
                  de carbono.
               </p>
               <p style={{ ...styles.cardTextP, color: colorPalette.lightGrey[200] }}>
                  El reciclaje de acero ahorra aproximadamente 74% y 95% de energía en comparación con la producción a
                  partir de materias primas.
               </p>
            </div> */}
         </div>

         <div className="line2end">
            <div className="card-base cardG">
               <img style={styles.cardImg} src={imageG} alt="cardG"></img>
               <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.red[300], opacity: '0.75' }}></div>
               <div style={styles.cardText}>
                  <p style={{ ...styles.cardTextP, color: colorPalette.lightGrey[200] }}>
                     Cada ciudadana y ciudadano chileno genera 1.2 kg de residuos cada día. Más de 430 kg por año. Por
                     cada ciudadano.
                  </p>
                  <p style={{ ...styles.cardTextP, color: colorPalette.lightGrey[200] }}>
                     <b>Son más de 8.1 millones de toneladas al año en chile.</b>
                  </p>
               </div>
            </div>

            <div className="card-base cardH">
               {/* <img style={styles.cardImg} src={imageH} alt="cardH"></img> */}
               <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.green[500], opacity: '0.8' }}></div>
               <div style={styles.cardText}>
                  {/* <h1 id="cards-h1" style={styles.cardTextH1}>
                     AHORRO
                  </h1> */}
                  <p id="cards-p" style={styles.cardTextP}>
                     Cerca del 80% de estos residuos pueden ser recuperados a través de estrategias de reciclaje de
                     envases y embalajes y compostaje de la fracción orgánica. Debo el grafico de torta :P
                  </p>
               </div>
            </div>
         </div>

         {/* LINEA 3 */}

         <div className="card-base cardI">
            <img style={styles.cardImg} src={imageI} alt="cardI"></img>
            <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.green[500], opacity: '0.6' }}></div>
            {/* <div style={styles.cardText}>
               <h1 id="cards-h1" style={styles.cardTextH1}>
                  + AGUA
               </h1>
               <p style={styles.cardTextP}>
                  El reciclaje adecuado de plásticos puede ayudar a prevenir la contaminación del océano y proteger la
                  vida marina.
               </p>
               <p style={styles.cardTextP}>
                  El reciclaje de papel ahorra grandes cantidades de agua, ya que la producción de papel reciclado
                  utiliza hasta un 60% menos de agua en comparación con el papel virgen.
               </p>
            </div> */}
         </div>

         <div className="card-base cardJ">
            <img style={styles.cardImg} src={imageJ} alt="cardJ"></img>
            <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.yellow[500], opacity: '0.60' }}></div>
            <div style={styles.cardText}>
               {/* <h1 id="cards-h1-high" style={styles.cardTextH1high}>
                  MENOS VERTEDEROS{' '}
               </h1> */}
               <p style={styles.cardTextP}>
                  <b>
                     Diseñemos juntos proyectos habitacionales y comerciales con un hábitat y uso diario alineado a la
                     economía circular
                  </b>
               </p>
            </div>
         </div>

         <div className="card-base cardK">
            {/* <img style={styles.cardImg} src={imageK} alt="cardK"></img> */}
            <div style={{ ...styles.cardDiv, backgroundColor: colorPalette.burdeo[500], opacity: '0.8' }}></div>
            <div style={styles.cardText}>
               <h1 style={styles.cardTextH1}>CONTRIBUCION AL CAMBIO CLIMATICO</h1>
               <p style={styles.cardTextP}>
                  El reciclaje ayuda a reducir las emisiones de gases de efecto invernadero al disminuir la necesidad de
                  extraer, fabricar y transportar materias primas.
               </p>
            </div>
         </div>

         {/* contact div */}

         <div style={styles.contactDiv}>
            <p>¿Querés saber más sobre nosotros? ¿Querés saber mas sobre Economía Circular? </p>
            <p>Ponte en contacto enviando un correo electrónico a</p>
            <p>
               <b>hello@circolar.com</b>
            </p>
         </div>
      </div>
   );
}
