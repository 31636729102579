import { useEffect, useState } from 'react';
import colorPalette from '../styles/colors';
import profileGustavo from '../assets/img/profile/gustavo.png';
import profileGuadalupe from '../assets/img/profile/guadalupe.png';
import profileMunir from '../assets/img/profile/munir.png';
import logoImg from '../assets/img/logo.png';

export function About() {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   useEffect(() => {
      window.scrollTo(0, 0); // Desplaza la página al inicio al cargar la nueva ruta
   }, []);

   const isMobile = windowWidth < 700;

   const styles = {
      aboutContainer: {
         position: 'relative',
         top: '-70px',
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         paddingTop: isMobile ? '70px' : '130px',
         height: isMobile ? '1200px' : '1150px',
         backgroundColor: colorPalette.lightGrey[200],
         textAlign: 'center',
      },

      logoImg: {
         width: isMobile ? '130px' : '190px',
         height: 'auto',
         paddingTop: isMobile ? '0px' : '30px',
      },

      aboutTitle: {
         width: '100vw',
         fontFamily: '"Roboto", sans-serif',
         fontSize: '20px',
         height: isMobile ? '7px' : '',
         fontWeight: '400',
      },

      aboutParagraph: {
         width: isMobile ? '75vw' : '70vw',
         fontFamily: '"Roboto", sans-serif',
         fontSize: isMobile ? '12px' : '20px',
         fontWeight: '400',
         lineHeight: isMobile ? '2.9vh' : '2.5vw',
      },

      aboutDescriptionContainer: {
         position: 'relative',
         display: 'flex',
         flexWrap: isMobile ? 'wrap' : 'nowrap',
         alignItems: 'center',
         justifyContent: 'center',
         width: '70vw',
         backgroundColor: colorPalette.lightGrey[200],
         textAlign: 'center',
         paddingTop: isMobile ? '5vh' : '100px',

         img: {
            height: isMobile ? '100px' : '160px',
            borderRadius: '100px',
         },

         paragraph: {
            width: '100%',
            fontFamily: '"Roboto", sans-serif',
            fontSize: isMobile ? '12px' : '16px',
            fontWeight: '300',
            lineHeight: isMobile ? '2.9vh' : '2.5vw',
            paddingLeft: isMobile ? '1.2vw' : '36px',
            paddingRight: isMobile ? '1.2vw' : '24px',
         },
      },
   };

   return (
      <div style={styles.aboutContainer} id="about">
         {!isMobile && <img src={logoImg} alt="Logo" className="navbar-logo" style={styles.logoImg} />}
         <p style={styles.aboutParagraph}>
            {/*  <i>
               Somos una organización apasionada por la sostenibilidad y el cuidado de la naturaleza. En el corazón de
               nuestra misión está la promoción de la economía circular y el diseño de sistemas de reciclaje innovadores
               y efectivos. Creemos que cada acción cuenta en la construcción de un futuro sostenible. Nos dedicamos a
               desarrollar soluciones personalizadas que impulsan la reutilización de recursos y reducen los residuos.
               Trabajamos en estrecha colaboración con empresas, comunidades y organizaciones para fomentar un enfoque
               ecológico en sus operaciones. Juntos, estamos comprometidos en diseñar un habitar desde la Economía
               circular para cerrar el ciclo de los materiales. Únete a nosotros en esta emocionante travesía hacia un
               mundo sustentable y consciente del medio ambiente.
            </i> */}
            <i>
               Somos un equipo de profesionales apasionados por la sustentabilidad. Promovemos la economía circular a
               través de nuestro trabajo diario. Creemos que cada acción en la construcción de futuro y presente
               sostenible. Tenemos experiencia en el diseño de soluciones ajustadas a requerimientos específicos en
               diferentes escalas de proyectos.
            </i>
         </p>
         <div style={styles.aboutDescriptionContainer}>
            <img src={profileGuadalupe} style={styles.aboutDescriptionContainer.img} alt="guadalupe" />
            <p style={styles.aboutDescriptionContainer.paragraph}>
               <b>Guadalupe Hoyos Gijón</b> Arquitecta con más de 5 años de experiencia en proyectos multidisciplinarios
               con enfoque en el impacto social y medioambiental. Especializada en el diseño de sistemas de reciclaje
               para proyectos de arquitectura de diversas escalas. Socia fundadora de CIRCOLAR
            </p>
         </div>
         <div style={styles.aboutDescriptionContainer}>
            <img src={profileMunir} style={styles.aboutDescriptionContainer.img} alt="munir" />
            <p style={styles.aboutDescriptionContainer.paragraph}>
               <b>Munir W. Rumie G.</b> Ingeniero en biotecnología ambiental, más de 8 años de experiencia en la
               industria de la economía circular, reciclaje, compostaje y diseño de sistemas de gestión de residuos.
               Actual encargado de proyectos de revalorización del Centro de Tratamiento Integral de Residuos La Laja,
               en Puerto Varas. Socio fundador de CIRCOLAR
            </p>
         </div>
         <div style={styles.aboutDescriptionContainer}>
            <img src={profileGustavo} style={styles.aboutDescriptionContainer.img} alt="gustavo" />
            <p style={styles.aboutDescriptionContainer.paragraph}>
               <b>Gustavo Bulaschevich</b> Arquitecto con más de 13 años de experiencia en proyectos y ejecución de
               obras en diferentes países y sistemas constructivos. En los últimos 4 años fusioné la arquitectura con el
               mundo IT en el desarrollo de gemelos digitales, considerándolos una herramienta fundamental para la
               realidad cada vez más compleja, en la cual nos desarrollamos. Socio fundador de CIRCOLAR
            </p>
         </div>
      </div>
   );
}
