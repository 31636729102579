import { useEffect, useState } from 'react';
import '../components/Banner.css';
import colorPalette from '../styles/colors';

import videoBanner from '../assets/video/manos.mp4';
import alternativeVideo from '../assets/video/manos-mobile.mp4';

export function Banner() {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   useEffect(() => {
      window.scrollTo(0, 0); // Desplaza la página al inicio al cargar la nueva ruta
   }, []);

   // STYLES

   const isMobile = windowWidth < 700;

   const styles = {
      bannerContainer: {
         position: 'relative',
         top: '-65px',
      },

      bannerTextContainer: {
         position: 'absolute',
         bottom: isMobile ? '5vh' : '48px',
         left: isMobile ? '7vw' : '24px',

         display: 'flex',
         flexWrap: 'wrap',
         alignContent: 'space-around',

         width: '100vw',
         maxWidth: isMobile ? '600px' : '800px',
         height: isMobile ? '40vw' : '250px',
      },

      bannerText: {
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         height: isMobile ? '7vw' : '20px',
         margin: '0',
         marginTop: isMobile ? '-10px' : '-10px',
         color: colorPalette.primary[400],
         backgroundColor: isMobile ? colorPalette.yellow[500] : 'rgba(0, 0, 0, 0)',

         paddingBottom: '4px',

         fontSize: isMobile ? '12.8vw' : '80px',
         fontWeight: '700',
         letterSpacing: isMobile ? '0px' : '0.8px',

         background: {
            display: 'flex',
            flexDirection: 'column',

            height: isMobile ? '20px' : '54px',
            justifyContent: 'center',

            paddingTop: '4px',

            backgroundColor: isMobile ? 'rgba(0, 0, 0, 0)' : colorPalette.yellow[500],
            opacity: '85%',
         },
      },

      bannerTextHashtag: {
         display: isMobile ? 'none' : 'block',

         position: 'absolute',
         bottom: '48px',
         right: '24px',

         margin: '0',
         marginTop: '-95px',
         color: colorPalette.primary[400],
         fontSize: '50px',
         fontWeight: '700',
         opacity: '93%',
      },
   };

   return (
      <div style={styles.bannerContainer} className="container" id="init">
         <video autoPlay loop muted>
            <source src={isMobile ? alternativeVideo : videoBanner} type="video/mp4" />
            Tu navegador no soporta la reproducción de video.
         </video>

         <div style={styles.bannerTextContainer}>
            <div style={styles.bannerText.background}>
               <h1 style={styles.bannerText}>DISEÑO</h1>
            </div>

            <div style={styles.bannerText.background}>
               <h1 style={styles.bannerText}>DE SISTEMAS</h1>
            </div>

            <div style={styles.bannerText.background}>
               <h1 style={styles.bannerText}>DE RECICLAJE</h1>
            </div>
         </div>

         <h1 style={styles.bannerTextHashtag}>#RECICLARESTENDENCIA</h1>
      </div>
   );
}
