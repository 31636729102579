import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, useScrollTrigger, Slide, Button, Box, Menu, MenuItem } from '@mui/material';
import colorPalette from '../styles/colors';
import logoImg from '../assets/img/logo.png';
import { Link } from 'react-router-dom';

import { IonIcon } from '@ionic/react';
import { menuOutline } from 'ionicons/icons';

const handleClickScrollInit = () => {
   const init = document.getElementById('init');
   if (init) {
      init.scrollIntoView({ behavior: 'smooth' });
   }
};

/* const handleClickScrollProjects = () => {
   const projects = document.getElementById('projects');
   if (projects) {
      projects.scrollIntoView({ behavior: 'smooth' });
   }
}; */

const handleClickScrollAbout = () => {
   const about = document.getElementById('about');
   if (about) {
      about.scrollIntoView({ behavior: 'smooth' });
   }
};

const handleClickScrollContact = () => {
   const contact = document.getElementById('contact');
   if (contact) {
      contact.scrollIntoView({ behavior: 'smooth' });
   }
};

function HideOnScroll(props) {
   const { children, window } = props;
   const trigger = useScrollTrigger({
      target: window ? window() : undefined,
   });

   return (
      <Slide appear={false} direction="down" in={!trigger}>
         {children}
      </Slide>
   );
}

HideOnScroll.propTypes = {
   children: PropTypes.element.isRequired,
   window: PropTypes.func,
};

export function HideAppBar(props) {
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   // STYLES

   const isMobile = windowWidth < 700;

   const styles = {
      link: {
         textDecoration: 'none',
         color: 'inherit',
      },

      logoImg: {
         width: isMobile ? '130px' : '150px', // Cambia el ancho deseado
         height: 'auto', // El alto se ajustará automáticamente para mantener la proporción
      },

      navBarButton: {
         display: isMobile ? 'none' : '',

         width: isMobile ? '55px' : '115px',
         height: isMobile ? '25px' : '50px',
         paddingTop: '36px',
         fontSize: isMobile ? '10px' : '20px',
         fontWeight: '500',
         color: colorPalette.primary[400],
         backgroundColor: 'rgba(226, 230, 232, 0.5)',
         marginTop: -20,
         borderRadius: '0px 0px 5px 3px',
      },

      navBarBurger: {
         display: isMobile ? 'block' : 'none',

         button: {
            color: colorPalette.lightGrey[500],
            fontSize: '32px',
            marginTop: -10,
            marginRight: -25,
         },
      },
   };

   return (
      <div>
         <HideOnScroll {...props}>
            <AppBar className="tool-bar" sx={{ background: 'rgba(0, 0, 0, 0)', boxShadow: 'none' }}>
               <Toolbar>
                  <Link to="/">
                     <img
                        src={logoImg}
                        alt="Logo"
                        onClick={handleClickScrollInit}
                        className="navbar-logo"
                        style={styles.logoImg}
                     />
                  </Link>

                  <Box sx={{ flexGrow: 1 }} />

                  <Link to="/about">
                     <Button color="inherit" style={styles.navBarButton}>
                        NOSOTROS
                     </Button>
                  </Link>

                  <Box sx={{ width: '30px' }} />

                  <Link to="/services">
                     <Button color="inherit" onClick={handleClickScrollAbout} style={styles.navBarButton}>
                        SERVICIOS
                     </Button>
                  </Link>

                  <Box sx={{ width: '30px' }} />

                  <Link to="/contact">
                     <Button color="inherit" onClick={handleClickScrollContact} style={styles.navBarButton}>
                        CONTACTO
                     </Button>
                  </Link>

                  <div style={styles.navBarBurger}>
                     <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                     >
                        <IonIcon style={styles.navBarBurger.button} icon={menuOutline} />
                     </Button>
                     <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                           'aria-labelledby': 'basic-button',
                        }}
                     >
                        <Link to="/about" style={styles.link}>
                           <MenuItem onClick={handleClose}>Nosotros</MenuItem>
                        </Link>
                        <Link to="/services" style={styles.link}>
                           <MenuItem onClick={handleClose}>Servicios</MenuItem>
                        </Link>
                        <Link to="/contact" style={styles.link}>
                           <MenuItem onClick={handleClose}>Contacto</MenuItem>
                        </Link>
                     </Menu>
                  </div>

                  <div style={styles.navBarbuttonContainer}></div>
               </Toolbar>
            </AppBar>
         </HideOnScroll>
         <Toolbar />
      </div>
   );
}
