import { useEffect, useState } from 'react';
import colorPalette from '../styles/colors';

import img1 from '../assets/img/services/img01.png';
import img2 from '../assets/img/services/img02.png';
import img3 from '../assets/img/services/img03.png';
import img4 from '../assets/img/services/img04.png';

export function Services() {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   useEffect(() => {
      window.scrollTo(0, 0); // Desplaza la página al inicio al cargar la nueva ruta
   }, []);

   // STYLES

   const isMobile = windowWidth < 700;

   const styles = {
      servicesContainer: {
         position: 'relative',
         top: isMobile ? '-220px' : '-85px',
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         paddingTop: isMobile ? '37vh' : '130px',
         paddingLeft: '10vw',
         paddingRight: '10vw',
         height: isMobile ? '700vw' : '305vw',
         backgroundColor: colorPalette.lightGrey[200],
         textAlign: 'left',
      },

      servicesTitle: {
         fontFamily: '"Roboto", sans-serif',
         fontSize: '20px',
         fontWeight: '400',
         height: '50px',
      },

      aboutParagraph: {
         width: '70vw',
         fontFamily: '"Roboto", sans-serif',
         fontSize: isMobile ? '12px' : '16px',
         fontWeight: '400',
         lineHeight: isMobile ? '2.9vh' : '2.5vw',
      },

      titleContainer: {
         display: 'flex',
         justifyContent: 'flex-end',
         paddingRight: isMobile ? '0.3vw' : '20vw',
         backgroundColor: '#4D4D4D',
         width: '100vw',
         height: isMobile ? '4.5vh' : '40px',
         marginTop: '8vh',
      },

      title: {
         margin: '1vh',
         fontFamily: '"Roboto", sans-serif',
         fontSize: isMobile ? '1.5vh' : '20px',
         fontWeight: '300',
         letterSpacing: '1px',
         color: colorPalette.lightGrey[200],
      },
   };

   return (
      <div style={styles.servicesContainer} id="about">
         {/* <p style={styles.servicesTitle}>
            <b>
               <i>NUESTROS SERVICIOS</i>
            </b>
         </p> */}
         <section style={styles.aboutParagraph}>
            <p>
               <b>Diseño Integral de Sistemas de Reciclaje</b>
            </p>
            <p>
               Ofrecemos soluciones especializadas en diseño y gestión de residuos, abordando cada aspecto para
               garantizar una implementación exitosa:
            </p>
            <p>
               <b>Adaptación Normativa y Diseño Personalizado</b>
            </p>
            <ul>
               <li>
                  Diseño de sistemas de reciclaje ajustados a las normativas vigentes y las cadenas de reciclaje en
                  desarrollo.
               </li>
               <li>Dimensionamiento de salas de acopio según requerimientos específicos del proyecto. </li>
               <li>Propuestas detalladas de infraestructura y maquinaria de compactación. </li>
            </ul>
            <p>
               <b>Análisis y Optimización</b>
            </p>
            <ul>
               <li>Cálculo preciso de volúmenes y generación de residuos en la comunidad. </li>
               <li>Elaboración de diagramas de procesos y esquemas de flujos para una operación eficiente. </li>
               <li>Diseño de esquemas de circulación, acopio, retiro y operación general del sistema. </li>
            </ul>
            <p>
               <b>Adaptabilidad y Efectividad</b>
            </p>
            <ul>
               <li> Definición de dimensiones mínimas y recomendadas para la infraestructura.</li>
               <li>
                  Ajustes arquitectónicos orientados a la futura operación y funcionamiento sostenible del sistema.
               </li>
            </ul>
            <p>
               Nuestros servicios integran una visión completa del diseño de sistemas de reciclaje, adaptados a las
               regulaciones actuales y las necesidades específicas de cada proyecto. Garantizamos eficiencia,
               cumplimiento normativo y un enfoque adaptativo para asegurar la viabilidad y sostenibilidad a largo
               plazo.
            </p>
         </section>
         <div style={styles.titleContainer}>
            <h2 style={styles.title}>ADAPTACIÓN NORMATIVA Y DISEÑO PERSONALIZADO</h2>
         </div>
         <img src={img1} style={{ width: '80vw', paddingTop: '10vh' }} alt="img1" />

         <div style={styles.titleContainer}>
            <h2 style={styles.title}>ANÁLISIS Y OPTIMIZACIÓN</h2>
         </div>
         <img src={img2} style={{ width: '80vw', paddingTop: '10vh' }} alt="img2" />

         <div style={styles.titleContainer}>
            <h2 style={styles.title}>MÚLTIPLES ESCALAS</h2>
         </div>
         <img src={img3} style={{ width: '80vw', paddingTop: '10vh' }} alt="img3" />

         <div style={styles.titleContainer}>
            <h2 style={styles.title}>ADAPTABILIDAD Y EFECTIVIDAD</h2>
         </div>
         <img src={img4} style={{ width: '80vw', paddingTop: '10vh' }} alt="img4" />
      </div>
   );
}
